declare global {
  interface Window {
    _env_: any;
  }
}

const env = {
  ...process.env,
  ...window._env_,
};

export const VERSION = env.REACT_APP_VERSION;
export const ENVIRONMENT = env.REACT_APP_ENVIRONMENT;
export const API_URL = env.REACT_APP_API_URL;
